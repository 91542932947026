import { useContext, useEffect, useRef, useState } from "react";
import { pause } from "./Utils";
import { DisplayInfo } from "./contexts/Contexts";

/**
 * @template T
 * @param {T} init
 * @returns {UseStateRef<T>}
 */
export const useStateRef = (init) => {
  const [state, setState] = useState(init);
  const ref = useRef(state);
  ref.current = state;

  return [state, setState, ref];
};

/**
 * @param {EventListener} callback
 * @param {React.DependencyList} deps
 * @param {number} ms - Delay callback
 */
export const useOnResize = (callback, deps = [], ms = 0) => {
  const callbackRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (ms > 0) {
      callbackRef.current = () => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(callback, ms);
      };
    } else {
      callbackRef.current = callback;
    }

    window.addEventListener("resize", callbackRef.current);

    return () => {
      window.removeEventListener("resize", callbackRef.current);
    };
  }, [...deps]);
};

/**
 * @param {() => boolean} predicate
 * @param {React.DependencyList} deps
 */
export const useContentBlock = (predicate, deps = []) => {
  const { isPortrait } = useContext(DisplayInfo);
  const [contentBlocked, setContentBlocked] = useState(isPortrait);

  if (contentBlocked || isPortrait) {
    document.body.classList.add("content-blocked");
  } else {
    document.body.classList.remove("content-blocked");
  }

  useOnResize(() => {
    setContentBlocked(predicate?.());
    pause(200).then(() => setContentBlocked(predicate?.()));
  }, [...deps]);

  useEffect(() => {
    setContentBlocked(predicate?.());

    return () => document.body.classList.remove("content-blocked");
  }, [...deps, isPortrait]);
};
