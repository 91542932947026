import { ModalContext } from "./Contexts";

/**
 * @type {React.Context<ModalState>["Provider"]}
 * @param {{ elements: ModalState }}
 */
const ModalContextProvider = ({ elements, children }) => {
  return (
    <ModalContext.Provider value={elements}>{children}</ModalContext.Provider>
  );
};

export default ModalContextProvider;
