import { IconChevronDown } from "@tabler/icons-react";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import Card from "./Card";
import CheckBox from "./CheckBox";
import StudyIndicator from "./StudyIndicator";

const formatter = new Intl.DateTimeFormat("en-US", {
  timeZone: "America/Los_Angeles",
  day: "numeric",
  month: "short",
  year: "numeric",
});

/**
 * @typedef {{
 *   participantId: string | number;
 *   firstName: string;
 *   lastName: string;
 *   email: string;
 *   preTestActivated: boolean;
 *   trainingsActivated: boolean;
 *   postTestActivated: boolean;
 *   progress: number;
 *   dateJoined: string;
 *   letter: string;
 * }} UserCardInfo
 *
 * @param {{
 *   user: UserCardInfo;
 *   selected: boolean;
 *   onToggleSelected: function;
 * }} props
 */
const UserListCard = (props) => {
  const { user, selected, onToggleSelected } = props;

  /**
   * @type {UseState<{
   *   participantId: string | number;
   *   fullName: string;
   *   email: string;
   *   dateJoined: string;
   *   preTest: "Activated" | "Not Activated";
   *   trainings: "Activated" | "Not Activated";
   *   postTest: "Activated" | "Not Activated";
   *   progress: number;
   *   letter: string;
   * }>}
   */
  const [parsedUser, setParsedUser] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const delayRef = useRef(null);

  useEffect(() => {
    setExpanded(false);
    setParsedUser(null);
    if (!user) return;

    const {
      participantId,
      firstName,
      lastName,
      email,
      dateJoined,
      preTestActivated,
      trainingsActivated,
      postTestActivated,
      progress,
      letter,
    } = user;

    const timestamp = Date.parse(dateJoined);
    if (isNaN(timestamp)) return;

    clearTimeout(delayRef.current);
    delayRef.current = setTimeout(() => {
      setParsedUser({
        participantId,
        fullName: `${firstName} ${lastName}`,
        dateJoined: formatter.format(timestamp),
        email,
        preTest: preTestActivated ? "Activated" : "Not Activated",
        trainings: trainingsActivated ? "Activated" : "Not Activated",
        postTest: postTestActivated ? "Activated" : "Not Activated",
        progress: isNaN(progress) ? "N/A" : `${progress}%`,
        letter,
      });
    }, 150);
  }, [user]);

  /** @param {{ k: string; v: string; className: string }} */
  const InfoRow = ({ k, v, className }) => (
    <div className={clsx("info-row", className)}>
      <span className="info-key">{k}:</span>
      <span className="info-value">{`${v}`}</span>
    </div>
  );

  return (
    <Card
      className={clsx(
        "user-list-card",
        !parsedUser && "loading",
        parsedUser && expanded && "expanded",
      )}
    >
      <div className="expand-toggle" onClick={() => setExpanded(!expanded)} />
      <div className="user-data-header">
        <CheckBox
          className="select-check"
          state={[selected, onToggleSelected]}
          variant="dark"
        />
        <span className="user-name">{parsedUser?.fullName}</span>
        <span className="user-pid">{parsedUser?.participantId}</span>
        <span className="user-study">
          <StudyIndicator letter={parsedUser?.letter} />
        </span>
        <span className="user-progress">{parsedUser?.progress}</span>
        <IconChevronDown
          className={clsx("expand-arrow", expanded && "open")}
          size="2rem"
          stroke={2.4}
        />
      </div>
      <div className="expanded-info">
        <InfoRow k="Email" v={parsedUser?.email} />
        <InfoRow k="Joined on" v={parsedUser?.dateJoined} />
        <InfoRow k="Last Activity" v="M1 S2" />

        <InfoRow
          k="Pre-Test"
          v={parsedUser?.preTest}
          className={parsedUser?.preTest === "Activated" ? "green" : "red"}
        />
        <InfoRow
          k="Training"
          v={parsedUser?.trainings}
          className={parsedUser?.trainings === "Activated" ? "green" : "red"}
        />
        <InfoRow
          k="Post-Test"
          v={parsedUser?.postTest}
          className={parsedUser?.postTest === "Activated" ? "green" : "red"}
        />
      </div>
    </Card>
  );
};

/**
 * @param {{
 *   someSelected: boolean;
 *   allSelected: boolean;
 *   onSelectAll: Function;
 *   onDeselectAll: Function;
 *   selectDisabled: boolean;
 * }} props
 */
export const UserListHeader = (props) => {
  const {
    someSelected,
    allSelected,
    onSelectAll,
    onDeselectAll,
    selectDisabled,
  } = props;

  const [shiftDown, setShiftDown] = useState(false);
  const [selectHovered, setSelectHovered] = useState(false);

  window.onkeydown = (event) => setShiftDown(event.shiftKey);
  window.onkeyup = (event) => setShiftDown(event.shiftKey);

  return (
    <Card
      className={clsx("user-list-header")}
      bgColor="var(--list-header-color)"
    >
      <div className="user-data-header">
        <CheckBox
          className="select-check"
          state={[someSelected, someSelected ? onDeselectAll : onSelectAll]}
          icon={allSelected ? "check" : shiftDown ? "plus" : "minus"}
          variant="dark"
          disabled={selectDisabled}
        />
        <span className="user-name">Full Name</span>
        <span className="user-pid">Participant ID</span>
        <span className="user-study">Study</span>
        <span className="user-progress">Progress</span>
        <IconChevronDown
          className="expand-arrow"
          size="2rem"
          visibility="hidden"
        />
      </div>
    </Card>
  );
};

export default UserListCard;
