import { useOnResize } from "@src/Hooks";
import { SVG } from "@src/components/SVG";
import { useEffect, useRef, useState } from "react";
import { pause } from "../Utils";
import "../styles/overlays.css";
import { DisplayInfo } from "./Contexts";

const infoClass = {
  isTablet: "display-tablet",
  isMobile: "display-mobile",
  isCompact: "display-compact",
  isWideScreen: "display-widescreen",
  isStandard: "display-standard",
  isHighDPI: "display-high-dpi",
  isPortrait: "display-portrait",
  resizing: "resizing",
  canHover: "can-hover",
};

/** @returns {DisplayState} */
export function updateDisplayInfo(resizing = false) {
  const html = document.documentElement;
  const aspectRatio = html.clientWidth / html.clientHeight;
  const minDimension = Math.min(html.clientWidth, html.clientHeight);
  const isPortrait = html.clientWidth <= html.clientHeight;

  return {
    isTablet: html.clientWidth <= 850,
    isMobile: minDimension <= 500,
    isCompact: minDimension <= 420,
    isWideScreen: aspectRatio >= 16 / 9 && html.clientWidth > 700,
    isStandard: aspectRatio <= 4 / 3,
    isHighDPI: html.clientWidth >= 1000,
    isPortrait,
    resizing,
    canHover: window.matchMedia("(hover: hover)").matches,
  };
}

/** @type {React.Context<DisplayState>["Provider"]} */
const DisplayInfoProvider = ({ children }) => {
  const [displayInfo, setDisplayInfo] = useState(updateDisplayInfo());
  const resizeTimeout = useRef(null);
  const html = document.documentElement;
  const body = document.body;

  useOnResize(() => {
    clearTimeout(resizeTimeout.current);
    setDisplayInfo(updateDisplayInfo(true));

    body.style.setProperty("--device-root-height", `${html.clientHeight}px`);

    resizeTimeout.current = setTimeout(async () => {
      await pause(window.resizeEndDelay || 100);
      setDisplayInfo(updateDisplayInfo());
      body.classList.remove(infoClass.resizing);
    }, 150);
  }, []);

  useEffect(() => {
    for (let field in displayInfo) {
      const classToken = infoClass[field];
      if (!classToken) continue;
      if (displayInfo[field]) {
        body.classList.add(classToken);
      } else {
        body.classList.remove(classToken);
      }
    }
  }, [displayInfo]);

  return (
    <DisplayInfo.Provider value={displayInfo}>
      <div className="portrait-content-block">
        <SVG src="mobile-phone" />
        <span>Please turn your device</span>
      </div>
      {/* <div className="fullscreen-notice">
        <span>Enter fullscreen for a better experience</span>
        <Button
          label="Enter Fullscreen"
          icon={<IconWindowMaximize />}
          onClick={() => document.body.requestFullscreen()}
          gap="1rem"
          reverse
        />
      </div> */}
      {children}
    </DisplayInfo.Provider>
  );
};

export default DisplayInfoProvider;
