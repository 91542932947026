import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import "../styles/page-not-found.css";

function PageNotFound() {
  const navigate = useNavigate();
  document.body.id = "page-not-found";

  return (
    <>
      <Helmet>
        <title>Page Not Found | Hazard Perception</title>
      </Helmet>
      <header>Page Not Found</header>
      <p className="not-found-msg">
        It looks like the page you're looking for doesn't exist.
      </p>
      <Button
        label="Go to Homepage"
        variant="light"
        depth="1.2rem"
        onClick={() => {
          navigate("/home");
        }}
        fontSize="2rem"
      />
    </>
  );
}

export default PageNotFound;
