import { useOnResize, useStateRef } from "@src/Hooks";
import { IconPlayerStopFilled, IconVolume, IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { pause } from "../Utils";
import { SVG } from "../components/SVG";
import TextBubble from "../components/TextBubble";
import Tooltip from "../components/Tooltip";
import { DisplayInfo, ScenarioInfo } from "../contexts/Contexts";
import { Button } from "./Button";
import Card from "./Card";
import { InstructionParser } from "./InstructionParser";

/** @type {ForwardRef<InstructionProps, RefType<Card>>} */
const ScenarioInstructions = forwardRef((props, ref) => {
  const { cardRef, forceMini, disabled } = props;
  const { states, actions } = useContext(ScenarioInfo);
  const { isMobile, canHover } = useContext(DisplayInfo);

  // prettier-ignore
  const { 
    frameOpacity, 
    avatarVariant, 
    scenarioWindow, 
    currInstructions, 
  } = states;

  const [miniChecked, setChecked] = useState(false);
  const [isBubbleMini, setIsBubbleMini] = useState(false);
  const [bubbleVisited, setBubbleVisited] = useState(false);
  const [bubbleShown, setBubbleShown] = useState(false);
  const prevInstructions = useRef(currInstructions);
  const [miniBubble, setMiniBubble, miniBubbleRef] = useStateRef(null);
  const [content, setContent, contentRef] = useStateRef(null);
  const [speaking, setSpeaking, speakingRef] = useStateRef(false);
  const [showTTS, setShowTTS] = useState(false);
  const [ttsVoice, setTTSVoice] = useState(null);
  const miniContentRef = useRef(null);
  const miniAvatarRef = useRef(null);
  const miniBoundsRef = useRef(null);
  const peekTimeout = useRef(null);
  const resizeTimeout = useRef(null);
  const hideTimeout = useRef(null);
  const blurFunctionRef = useRef(null);

  const TTSBtnIcon = speaking ? IconPlayerStopFilled : IconVolume;

  miniBubbleRef.current = miniBubble;
  OverlayScrollbars.plugin(ClickScrollPlugin);

  Object.assign(actions, { hideMiniInstructions, stopReadInstructions });

  // prettier-ignore
  useImperativeHandle(ref, () => {
    return cardRef.current;
  }, []);

  useOnResize(checkMini, [], 250);

  useOnResize(() => {
    if (isBubbleMini || !miniChecked) resetStaticBubble();
    const inst = miniBubbleRef.current;
    setBubbleShown(false);
    if (!inst || !inst.state.isMounted) return;

    inst.popper.style.opacity = 0;
    inst.disable();
    clearTimeout(resizeTimeout.current);
    resizeTimeout.current = setTimeout(() => {
      if (inst.state.isDestroyed) return;
      inst.popper.style.opacity = 1;
      inst.enable();
      peekBubble(inst);
    }, 300);
  }, []);

  useEffect(() => {
    const voices = speechSynthesis.getVoices();
    if (voices.length) {
      getEnglishVoices(voices);
      return;
    }
    speechSynthesis.onvoiceschanged = () => {
      getEnglishVoices(speechSynthesis.getVoices());
    };
  }, [content]);

  useEffect(() => {
    if (isBubbleMini || !miniChecked) resetStaticBubble();
    if (!frameOpacity) return;
    pause(500).then(checkMini);
  }, [frameOpacity]);

  useEffect(() => {
    if (frameOpacity) checkMini();
  }, [isMobile]);

  useEffect(() => {
    if (blurFunctionRef.current) {
      document.body.removeEventListener("click", blurFunctionRef.current);
    }
    blurFunctionRef.current = bubbleOnBlur;
    document.body.addEventListener("click", blurFunctionRef.current);

    if (!miniBubble || !miniBubble.popper) return;
    if (canHover) {
      miniBubble.popper.addEventListener("mouseenter", () => {
        avatarInteractToggle(true);
      });
      miniBubble.popper.addEventListener("mouseleave", () => {
        avatarInteractToggle(false);
      });
    }
  }, [miniBubble]);

  useEffect(() => {
    if (!miniBubble) return;
    if (!isBubbleMini && !forceMini) setBubbleShown(true);
  }, [isBubbleMini, forceMini, miniBubble]);

  useEffect(() => {
    if (!frameOpacity) return;
    const text = currInstructions;
    const bubble = miniBubbleRef.current;
    if (!bubble || !text || bubble.state.isDestroyed) return;
    prevInstructions.current = text;
    if (!forceMini) {
      pause(250).then(() => peekBubble(bubble));
    } else {
      setBubbleVisited(false);
    }
  }, [frameOpacity, currInstructions, miniBubble]);

  useEffect(() => {
    stopReadInstructions();
    const inst = miniBubbleRef.current;
    if (!inst || !inst.state.isMounted) return;

    if (bubbleShown) bubbleResize(inst);
  }, [currInstructions]);

  /** @param {SpeechSynthesisVoice[]} voices */
  function getEnglishVoices(voices) {
    const englishVoices = voices.filter((voice, idx) => {
      if (voice.lang === "en-US") {
        return voice;
      }
    });
    if (englishVoices.length) {
      setShowTTS(!!content);
      setTTSVoice(englishVoices[0]);
    }
  }

  function getStaticBubble() {
    if (!contentRef.current) return;
    let bubble = contentRef.current;
    if (!bubble) return;
    while (!bubble.classList.contains("text-bubble")) {
      bubble = bubble.parentNode;
    }
    return bubble;
  }

  function resetStaticBubble() {
    const bubble = getStaticBubble();
    if (bubble) {
      bubble.style.minWidth = "unset";
      bubble.style.maxWidth = "unset";
      bubble.style.wordBreak = "break-all";
    }
  }

  async function hideMiniInstructions(callback = () => {}) {
    if (speakingRef.current) return;
    const bubble = miniBubbleRef.current;
    console.log(bubbleShown);
    if (bubbleShown) {
      bubble.hide();
      await pause(bubble.props.duration[1]);
    }
    callback();
  }

  async function checkMini() {
    const bubble = getStaticBubble();
    if (!bubble) return;
    let fixedWidth = bubble.style.minWidth;

    // prevent layout shifts
    // from hidden instruction card
    if (cardRef.current) {
      const html = document.documentElement;
      const oldWidth = bubble.style.getPropertyValue("--curr-window-width");
      const oldHeight = bubble.style.getPropertyValue("--curr-window-height");
      const card = cardRef.current;
      const cardPadRaw = window.getComputedStyle(card).paddingInline;
      const cardPad = parseInt(cardPadRaw && cardPadRaw.toString());
      const diffWidth = parseInt(oldWidth) !== html.clientWidth;
      const diffHeight = parseInt(oldHeight) !== html.clientHeight;
      if (diffWidth || diffHeight) {
        bubble.style.minWidth = "unset";
        bubble.style.maxWidth = "unset";
        fixedWidth = card.offsetWidth - 2 * cardPad;
        bubble.style.setProperty("--curr-window-width", html.clientWidth);
        bubble.style.setProperty("--curr-window-height", html.clientHeight);
        bubble.style.wordBreak = "normal";
      }
      bubble.style.minWidth = `${fixedWidth}px`;
      bubble.style.maxWidth = `${fixedWidth}px`;
    }

    const overflowRef = bubble.previousSibling;
    if (!bubble.offsetHeight || !bubble.scrollHeight) return;
    const textWider = bubble.offsetWidth < overflowRef.offsetWidth;
    const { fontSize } = window.getComputedStyle(document.body);
    const tooNarrow = bubble.offsetWidth < 12 * parseInt(fontSize.toString());
    const isMini = textWider || tooNarrow;

    setIsBubbleMini(isMini);
    setChecked(true);
  }

  async function peekBubble(inst) {
    if (inst.state.isDestroyed) return;
    inst.show();
    clearTimeout(peekTimeout.current);
    peekTimeout.current = setTimeout(() => {
      if (inst.state.isDestroyed || speakingRef.current) return;
      if (inst.popper.childNodes[0].matches(":hover")) {
        inst.hideWithInteractivity({});
      } else {
        inst.hide();
      }
    }, inst.props.delay[0] + 5000);
  }

  async function bubbleOnShow(inst) {
    if (forceMini) {
      const tooltip = inst.popper.childNodes[0];
      tooltip.style.setProperty("width", "0px", "important");
      tooltip.style.minWidth = 0;
      setBubbleVisited(true);
      await pause(50);
    }
    await bubbleResize(inst);
    await pause(inst.props.delay[0] || 300);
    setBubbleShown(true);
    if (inst.state.isDestroyed) return;
    const tooltip = inst.popper.childNodes[0];
    tooltip.style.setProperty("--scrollbar-opacity", "60%");
  }

  async function bubbleOnHide(inst) {
    if (!bubbleShown) return;
    const tooltip = inst.popper.childNodes[0];
    tooltip.style.setProperty("--scrollbar-opacity", 0);
    if (forceMini) {
      tooltip.style.minWidth = 0;
      tooltip.style.setProperty("width", "0px", "important");
    } else {
      const content = tooltip.childNodes[0];
      content.style.minHeight = 0;
      tooltip.style.setProperty("--max-height", "0px");
    }
    await pause(inst.props.delay[0] || 300);
    setBubbleShown(false);
  }

  async function bubbleResize(inst) {
    const tooltip = inst.popper.childNodes[0];
    tooltip.style.setProperty("--scrollbar-opacity", 0);
    const content = tooltip.childNodes[0];
    const text = miniContentRef.current;
    while (!text.scrollHeight) {
      await pause(10);
      if (inst.state.isDestroyed) return;
    }
    if (forceMini) {
      tooltip.style.minWidth = "100%";
      tooltip.style.setProperty("width", "100%", "important");
      tooltip.style.setProperty("--max-height", null);
    } else {
      const textHeight = text.scrollHeight;
      const top = cardRef.current.offsetTop;
      const bottom = miniAvatarRef.current.offsetTop;
      const maxHeight = `${bottom - top}px - 5.5rem`;
      const ttMaxHeight = `calc(${maxHeight} - var(--header-size))`;
      const contentMaxHeight = `calc(${maxHeight})`;
      const ttNewVal = `min(${textHeight}px, ${ttMaxHeight})`;
      const contentNewVal = `min(calc(${textHeight}px + var(--header-size)), ${contentMaxHeight})`;
      tooltip.style.setProperty("--max-height", ttNewVal);
      content.style.minHeight = contentNewVal;
    }
    await pause(300);
    tooltip.style.setProperty("--scrollbar-opacity", "60%");
  }

  function clearBubbleTimeout() {
    clearTimeout(peekTimeout.current);
    if (!miniBubbleRef.current) return;
    if (miniBubbleRef.current.isDestroyed) return;
    else if (!miniBubbleRef.current.popperInstance) {
      miniBubbleRef.current.show();
    }
  }

  function readInstructions() {
    const contentElement = contentRef.current;
    if (!contentElement || !contentElement.textContent) {
      return;
    }
    const text = contentElement.textContent;
    const instructions = new SpeechSynthesisUtterance(text);
    instructions.voice = ttsVoice;
    setSpeaking(true);
    speechSynthesis.speak(instructions);
    instructions.onend = () => setSpeaking(false);
  }

  function stopReadInstructions() {
    if (!speakingRef.current) return;
    speechSynthesis.cancel();
    setSpeaking(false);
  }

  /** @param {PointerEvent} event */
  function bubbleOnBlur(event) {
    if (speakingRef.current) return;
    const elt = event.target;
    const bubble = miniBubbleRef.current;
    const avatar = miniAvatarRef.current;
    if (elt.localName === "button") return;
    if (!bubble || !avatar || bubble.state.isDestroyed) return;
    if (bubble.popper && bubble.popper.contains(elt)) return;
    if (!avatar.contains(elt)) bubble.hide();
  }

  function avatarInteractToggle(show) {
    clearTimeout(hideTimeout.current);
    if (speakingRef.current) return;
    if (!miniBubbleRef.current) return;
    const bubble = miniBubbleRef.current;
    if (bubble.state.isDestroyed) return;
    if (show) {
      bubble.show();
    } else {
      hideTimeout.current = setTimeout(() => {
        bubble.hide();
      }, 100);
    }
    clearBubbleTimeout();
  }

  const miniAvatar = (
    <div
      className={clsx(
        "avatar-mini",
        avatarVariant,
        bubbleShown && "bubble-open",
        forceMini && !bubbleVisited && "pulse",
      )}
      onClick={(event) => {
        avatarInteractToggle(true);
        const avatar = event.target;
        if (avatar.classList.contains("bubble-open")) {
          avatar.classList.add("nudge");
          pause(300).then(() => avatar.classList.remove("nudge"));
        }
      }}
      onMouseEnter={canHover ? () => avatarInteractToggle(true) : null}
      onMouseLeave={canHover ? () => avatarInteractToggle(false) : null}
      ref={miniAvatarRef}
    >
      <SVG src={`avatar-mini-${avatarVariant}`} className="avatar-model" />
    </div>
  );

  const bubbleHeader = (
    <div className={clsx("bubble-header", isBubbleMini && "mini")}>
      {showTTS && (
        <Button
          className="tts-toggle"
          variant="dark"
          icon={<TTSBtnIcon className="tts-btn" stroke={2.4} />}
          onClick={speaking ? stopReadInstructions : readInstructions}
          tippy={{ content: speaking ? "Stop Reading" : "Read Aloud" }}
        />
      )}
      {isBubbleMini && (
        <Button
          className="close-bubble"
          variant={speaking ? "secondary" : "dark"}
          icon={<IconX stroke={2.8} />}
          onClick={() => avatarInteractToggle(false)}
          disabled={speaking}
          tippy={{ content: "Hide Instructions" }}
        />
      )}
    </div>
  );

  const miniBubbleComponent = (
    <Tooltip
      className={clsx(
        "mini-bubble",
        "text-bubble-container",
        forceMini && "force-mini",
      )}
      interactive
      visible={bubbleShown || speaking || undefined}
      appendTo={
        scenarioWindow && forceMini ? scenarioWindow.parentNode : document.body
      }
      onCreate={setMiniBubble}
      onShow={bubbleOnShow}
      onHide={bubbleOnHide}
      content={
        <>
          {bubbleHeader}
          <TextBubble
            detached
            indicateScroll
            alwaysShowScroll
            indicateDelayMS={forceMini && 300}
            indicateOn={[currInstructions]}
            bubbleShown={bubbleShown}
            ref={miniContentRef}
            innerHtml={currInstructions || prevInstructions.current}
          />
        </>
      }
      placement={forceMini ? "right" : "top"}
      reference={forceMini ? cardRef.current : miniAvatarRef.current}
      arrow={!forceMini && `<div class="text-bubble-tail-left" />`}
      disabled={disabled || !frameOpacity}
      duration={[250, 400]}
      delay={[0, 250]}
      zIndex={999}
      popperOptions={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              boundary: miniBoundsRef.current,
              mainAxis: !forceMini,
            },
          },
        ],
      }}
    />
  );

  return (
    <>
      <InstructionParser />
      <div className="mini-bubble-bounds" ref={miniBoundsRef} />
      <TextBubble
        className={clsx(
          (isBubbleMini || !miniChecked) && "hidden",
          isBubbleMini && miniChecked && "detached",
        )}
        bubbleShown={!isBubbleMini && miniChecked}
        headerButtons={bubbleHeader}
        tailPercentOffset={85}
        innerHtml={currInstructions}
        indicateOn={[currInstructions]}
        indicateScroll
        ref={setContent}
        onResize={miniChecked ? checkMini : null}
      />
      {isBubbleMini && miniBubbleComponent}
      {miniAvatar}
    </>
  );
});

export default ScenarioInstructions;
