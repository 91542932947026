import CheckBox from "./CheckBox";

/**
 * @param {{
 *   className: string;
 *   label: string;
 *   selected: boolean;
 *   onChange: function;
 *   info: JSX.Element;
 *   disabled: boolean;
 *   radio: boolean;
 *   after: any;
 * }} props
 */
const RadioButton = (props) => {
  const { selected, onChange, ...otherProps } = props;

  return <CheckBox radio state={[selected, onChange]} {...otherProps} />;
};

export default RadioButton;
