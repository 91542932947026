import { Button } from "@src/components/Button";
import Card from "@src/components/Card";
import CheckBox from "@src/components/CheckBox";
import DashContainer from "@src/components/DashContainer";
import PagerFooter from "@src/components/PagerFooter";
import RadioButton from "@src/components/RadioButton";
import ScrollList from "@src/components/ScrollList";
import UserListCard, { UserListHeader } from "@src/components/UserListCard";
import { DisplayInfo } from "@src/contexts/Contexts";
import { selectActions } from "@src/workers/constants";
import SelectionWorker from "@src/workers/userListWorker.js?worker";
import {
  IconChevronDown,
  IconFilter,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import axios from "axios";
import clsx from "clsx";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { ServerUrl, loadFor } from "../../Utils";
import InputField, {
  InputHeader,
  SelectField,
} from "../../components/InputField";
import ResearchModal from "../../components/ResearcherModal";
import StudyIndicator from "../../components/StudyIndicator";
import "../../styles/research-search.css";

export default function SearchUI() {
  document.body.id = "research-search";
  const navigate = useNavigate();

  const { isHighDPI } = useContext(DisplayInfo);

  const researcherInfo = {
    firstName: "Andrew",
    lastName: "Hellinger",
    email: "andrew@jhu.edu",
    role: "RESEARCHER",
  };

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [userData, setUserData] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(new Map());
  const [modalState, setModalState] = useState({ action: "enablePostTest" });
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [stableSearch, setStableSearch] = useState("");
  const [page, setPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    task: null,
    study: new Set(),
    completion: new Set(),
  });
  const [searchBy, setSearchBy] = useState("name");
  const [numUsers, setNumUsers] = useState(0);
  const [viewSelected, setViewSelected] = useState(false);
  const [hideFilters, setHideFilters] = useState(!isHighDPI);
  const overlayFilters = !isHighDPI;

  /** @type {React.MutableRefObject<Worker>} */
  const selectWorker = useRef(null);

  const task = {
    PRE_TEST: "preTest",
    TRAINING: "training",
    POST_TEST: "postTest",
  };
  const study = {
    VALIDATION_STUDY: "VALIDATION_STUDY",
    EXPERIMENT: "EXPERIMENT",
    EXPERIMENT_AM: "EXPERIMENT_AM",
    CONTROL: "CONTROL",
  };
  const completion = {
    ns: "notstarted",
    ip: "inprogress",
    done: "completed",
  };

  useEffect(() => {
    /** @type {Worker} */
    const worker = new SelectionWorker();
    worker.onmessage = ({ data }) => handleSelectionUpdate(data);
    selectWorker.current = worker;

    return () => worker?.terminate();
  }, []);

  useEffect(() => {
    const searchStabilizer = setTimeout(() => {
      setStableSearch(search);
    }, 1000);

    return () => clearTimeout(searchStabilizer);
  }, [search]);

  useEffect(() => {
    if (page === 1) {
      updateUserList();
    } else {
      setPage(1);
    }
  }, [filterOptions, itemsPerPage, stableSearch]);

  useEffect(() => {
    updateUserList(page);
  }, [page]);

  function processUserData(users = []) {
    const anyEmpty = users?.some((v) => !Object.entries(v).length);
    if (users.length && anyEmpty) return [];

    return users.map((user) => ({
      ...user,
      // TODO: calculate user progress
      progress: Math.round(Math.random() * 20) * 5,
      letter:
        user.role === "VALIDATION_STUDY"
          ? "V"
          : user.role === "EXPERIMENT"
            ? "H"
            : user.role === "CONTROL"
              ? "C"
              : user.role === "EXPERIMENT_AM"
                ? "A"
                : "V",
    }));
  }

  /** @param {URL} url */
  function fetchUsers(url) {
    setUserData([]);

    loadFor(250, () =>
      axios.get(url.href, { withCredentials: true }).then((res) => {
        if (res.status !== 200) throw new Error("Failed to fetch users");

        const { users = [], totalUsers = 0 } = res.data.data;
        return { users: processUserData(users), totalUsers };
      }),
    )
      .then(({ users, totalUsers }) => {
        setUserData(users);
        setNumUsers(totalUsers);

        selectWorker.current?.postMessage({
          action: selectActions.UPDATE_LIST,
          users,
        });
      })
      .catch(console.log);
  }

  function batchPostTestActivate() {
    const usersToActivate = Array.from([...selectedUsers.keys()]);
    axios
      .post(
        `${ServerUrl}/users/batch-activate-post-driving-test-user`,
        { userIds: usersToActivate },
        { withCredentials: true },
      )
      .then((res) => {
        console.log({ response: res, message: "Users sucessfully activated" });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  function handleSelectionUpdate({ type, users = new Map(), allSelected }) {
    switch (type) {
      case selectActions.UPDATE_LIST:
        setAllSelected(allSelected);
        return;
      case selectActions.SELECT:
        break;
      case selectActions.DESELECT:
        if (!users.size) {
          setViewSelected(false);
        }
        setAllSelected(false);
        break;
      case selectActions.SELECT_ALL:
        setAllSelected(true);
        break;
      case selectActions.DESELECT_ALL:
        setViewSelected(false);
        setAllSelected(false);
        break;
      default:
        break;
    }

    setSelectedUsers(users);
  }

  function selectUser(user) {
    selectWorker.current?.postMessage({
      action: selectActions.SELECT,
      users: [JSON.stringify(user)],
    });
  }

  function deselectUser(user) {
    selectWorker.current?.postMessage({
      action: selectActions.DESELECT,
      users: [JSON.stringify(user)],
    });
  }

  /** @param {MouseEvent | null} event */
  function selectAll(event) {
    if (event?.shiftKey) return deselectAll();

    if (allSelected) return;
    selectWorker.current?.postMessage({
      action: selectActions.SELECT_ALL,
    });
  }

  /** @param {MouseEvent | null} event */
  function deselectAll(event) {
    if (event?.shiftKey) return selectAll();

    selectWorker.current?.postMessage({
      action: selectActions.DESELECT_ALL,
    });
  }

  function handleCheckbox(user = null) {
    selectedUsers.has(user?.participantId)
      ? deselectUser(user)
      : selectUser(user);
  }

  function updateUserList(page = 1) {
    const url = new URL(`${ServerUrl}/users/search`);
    const options = [
      filterOptions["task"],
      ...filterOptions["completion"],
      ...filterOptions["study"],
    ];
    options.map((option) => {
      url.searchParams.set(option, true);
    });

    url.searchParams.set("page", page);
    url.searchParams.set("pageSize", itemsPerPage);
    if (stableSearch) {
      url.searchParams.set("search", stableSearch);
    }
    fetchUsers(url);
  }

  function taskOnChange(task) {
    setFilterOptions({ ...filterOptions, task });
  }

  /**
   * @param {keyof filterOptions} filterSet
   * @param {string} filterValue
   */
  function toggleFilterCheck(filterSet, filterValue) {
    setFilterOptions((curFilters) => {
      if (curFilters[filterSet]?.has?.(filterValue)) {
        curFilters[filterSet]?.delete?.(filterValue);
      } else {
        curFilters[filterSet]?.add?.(filterValue);
      }
      return { ...curFilters };
    });
  }

  function createUserCards(user, idx) {
    return (
      <UserListCard
        key={idx}
        user={user}
        selected={selectedUsers.has(user.participantId)}
        onToggleSelected={() => handleCheckbox(user)}
      />
    );
  }

  function createSelectedUserPreview(user, idx) {
    return <StudyIndicator key={idx} letter={user.letter} zIndex={3 - idx} />;
  }

  const filterLayout = (
    <>
      <SelectField
        id="search-by"
        className="filter-search"
        label="Search By"
        value={searchBy}
        options={{
          participantId: "Participant ID",
          name: "Name",
          email: "Email",
        }}
        onChange={(e) => setSearchBy(e.target.value)}
        disabled={filterOptions.task === null}
      />

      <div className="filter-group">
        <InputHeader className="filter-header" label="Task" />
        <RadioButton
          label="Pre-Test"
          selected={filterOptions.task === task.PRE_TEST}
          onChange={() => taskOnChange(task.PRE_TEST)}
        />
        <RadioButton
          label="Training"
          selected={filterOptions.task === task.TRAINING}
          onChange={() => taskOnChange(task.TRAINING)}
        />
        <RadioButton
          label="Post-Test"
          selected={filterOptions.task === task.POST_TEST}
          onChange={() => taskOnChange(task.POST_TEST)}
        />
      </div>

      <div className="filter-group">
        <InputHeader className="filter-header" label="Study" />
        <CheckBox
          label="Validation"
          state={[
            filterOptions.study.has(study.VALIDATION_STUDY),
            () => toggleFilterCheck("study", study.VALIDATION_STUDY),
          ]}
          disabled={filterOptions.task === null}
          after={<StudyIndicator letter="V" />}
        />
        <CheckBox
          label="Experiment HA"
          state={[
            filterOptions.study.has(study.EXPERIMENT),
            () => toggleFilterCheck("study", study.EXPERIMENT),
          ]}
          disabled={filterOptions.task === null}
          after={<StudyIndicator letter="H" />}
        />
        <CheckBox
          label="Experiment AM"
          state={[
            filterOptions.study.has(study.EXPERIMENT_AM),
            () => toggleFilterCheck("study", study.EXPERIMENT_AM),
          ]}
          disabled={filterOptions.task === null}
          after={<StudyIndicator letter="A" />}
        />
        <CheckBox
          label="Control"
          state={[
            filterOptions.study.has(study.CONTROL),
            () => toggleFilterCheck("study", study.CONTROL),
          ]}
          disabled={filterOptions.task === null}
          after={<StudyIndicator letter="C" />}
        />
      </div>

      <div className="filter-group">
        <InputHeader className="filter-header" label="Completion" />
        <CheckBox
          label="Not Started"
          state={[
            filterOptions.completion.has(completion.ns),
            () => toggleFilterCheck("completion", completion.ns),
          ]}
          disabled={filterOptions.task === null}
        />
        <CheckBox
          label="In Progress"
          state={[
            filterOptions.completion.has(completion.ip),
            () => toggleFilterCheck("completion", completion.ip),
          ]}
          disabled={filterOptions.task === null}
        />
        <CheckBox
          label="Complete"
          state={[
            filterOptions.completion.has(completion.done),
            () => toggleFilterCheck("completion", completion.done),
          ]}
          disabled={filterOptions.task === null}
        />
      </div>
    </>
  );

  return (
    <DashContainer
      userInfo={researcherInfo}
      afterTitle={
        <InputField
          className="user-search"
          type="text"
          id="search"
          autoComplete="search"
          value={search}
          placeholder="Search users..."
          iconLeft={<IconSearch />}
          width="15rem"
          onChange={(e) => setSearch(e.target.value)}
        />
      }
    >
      <Helmet>
        <title>Search | Hazard Perception</title>
      </Helmet>
      <div
        className={clsx(
          "main-search-container",
          hideFilters && "filters-hidden",
          overlayFilters && "overlay-filters",
        )}
      >
        <Card className="filter-container" bgColor="var(--main-bg-color)">
          <div className="title">
            Filters
            <IconX
              className="close-filters"
              size="1.8rem"
              stroke={3}
              onClick={() => setHideFilters(true)}
              style={{ marginLeft: "auto" }}
            />
          </div>
          <OverlayScrollbarsComponent className="filter-list" defer>
            {filterLayout}
          </OverlayScrollbarsComponent>
        </Card>
        <div
          className="search-results"
          onClick={() => {
            if (!overlayFilters || hideFilters) return;
            setHideFilters(true);
          }}
        >
          <div className="title">
            <Button
              className={clsx(
                "open-filters",
                !hideFilters && !overlayFilters && "hidden",
              )}
              variant="secondary"
              label={!overlayFilters && "Filter"}
              icon={<IconFilter stroke={2.7} />}
              onClick={() => setHideFilters(false)}
              tippy={{
                content: "Filter Search",
                disabled: !overlayFilters,
                zIndex: 100,
              }}
              tabIndex={-1}
            />
            <header>Search Users</header>
            {/* <Button
              label="Upload Users"
              variant="secondary"
              onClick={() => navigate("/research/upload")}
            /> */}
          </div>
          {/* TODO: select all actions (moved) */}
          <UserListHeader
            someSelected={selectedUsers.size > 0}
            allSelected={allSelected}
            onSelectAll={selectAll}
            onDeselectAll={deselectAll}
            selectDisabled={filterOptions.task === null}
          />
          {filterOptions.task === null ? (
            <div className="task-select-prompt">
              Please select a task in filters to view users
            </div>
          ) : (
            <>
              <Card
                className={clsx(
                  "selected-users",
                  !viewSelected && "compact",
                  !selectedUsers.size && "hidden",
                )}
                bgColor="var(--main-bg-color)"
              >
                <div
                  className="expand-toggle"
                  onClick={() => setViewSelected(!viewSelected)}
                />
                <div className="selection-header">
                  <span className="size">{selectedUsers.size}</span>
                  Selected
                  <span className="preview">
                    {Array.from(selectedUsers.values())
                      .slice(0, 3)
                      .map(createSelectedUserPreview)}
                    {selectedUsers.size > 3 && "+"}
                  </span>
                  <IconChevronDown
                    className={clsx("expand-arrow", viewSelected && "open")}
                    size="2rem"
                    stroke={2.4}
                    style={{ marginLeft: "auto" }}
                  />
                  <Button
                    className="selection-action"
                    label="Activate Post Test"
                    variant="light"
                    onClick={() => batchPostTestActivate()}
                    tabIndex={-1}
                  />
                </div>
                <ScrollList
                  className={clsx(
                    "user-cards selected",
                    !(selectedUsers.size && viewSelected) && "hidden",
                  )}
                  direction="vertical"
                  debounceMS={250}
                  defer
                >
                  {Array.from(selectedUsers.values()).map(createUserCards)}
                </ScrollList>
              </Card>
              <ScrollList
                className={clsx(
                  "user-cards",
                  selectedUsers.size && viewSelected && "hidden",
                )}
                direction="vertical"
                debounceMS={250}
                defer
              >
                {userData.map(createUserCards)}
              </ScrollList>
            </>
          )}
        </div>
      </div>
      <PagerFooter
        itemsPerPage={itemsPerPage}
        perPageOptions={[10, 25, 50, 75, 100]}
        onItemsPerPageChange={(e) => setItemsPerPage(e.target.value)}
        selectDisabled={filterOptions.task === null}
        page={page}
        numItems={numUsers}
        shownRange={isHighDPI ? 2 : 1}
        onPageChange={(page) => setPage(page)}
      />
      {isActionsOpen && (
        <ResearchModal
          openState={[isActionsOpen, setIsActionsOpen]}
          modalState={modalState}
        />
      )}
    </DashContainer>
  );
}
