import { useOnResize } from "@src/Hooks";
import {
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconDots,
  IconHelp,
  IconLogout2,
  IconPlayerPlayFilled,
  IconRefresh,
} from "@tabler/icons-react";
import clsx from "clsx";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Button } from "../components/Button";
import Card from "../components/Card";
import { SectionedProgressBar } from "../components/ProgressBar";
import ScenarioInstructions from "../components/ScenarioInstructions";
import { DisplayInfo, ScenarioInfo } from "../contexts/Contexts";
import ButtonToggle from "./ButtonToggle";
import { InputHeader } from "./InputField";
import { SVG } from "./SVG";

/** @type {ForwardRef<LeftSidebarProps, RefType<Card>>} */
export const LeftSidebar = forwardRef((props, ref) => {
  const { wideMobile, addIntroTarget, handleNavigate } = props;

  const { isMobile, resizing } = useContext(DisplayInfo);
  const { states, actions } = useContext(ScenarioInfo);

  const { toggleMobileFooter } = actions;
  const { activityState } = states;

  const hideMiniBubbleRef = useRef(null);
  const sidebarRef = useRef(null);

  // prettier-ignore
  useImperativeHandle(ref, () => {
    return sidebarRef.current;
  }, []);

  useEffect(() => {
    hideMiniBubbleRef.current = actions.hideMiniInstructions;
  }, [actions]);

  return (
    <>
      <Card
        className={clsx(
          "left",
          "sidebar",
          "instructions",
          isMobile && "mobile-sidebar-left",
          wideMobile && "wide-mobile",
        )}
        height="auto"
        bgColor="var(--card-bg-color)"
        ref={(ref) => {
          if (!ref) return;
          sidebarRef.current = ref;
          addIntroTarget?.(ref);
        }}
      >
        {isMobile && (
          <Button
            variant="dark"
            className="mobile-more-info"
            bgColor="var(--exit-bg-color)"
            icon={<IconDots stroke={2.5} style={{ scale: "1.5" }} />}
            onClick={() => {
              if (hideMiniBubbleRef.current) {
                hideMiniBubbleRef.current().then(toggleMobileFooter);
              } else {
                toggleMobileFooter();
              }
            }}
            tippy={{
              content: "Progress & More Info",
              placement: "left",
            }}
          />
        )}
        <ScenarioInstructions
          forceMini={isMobile}
          disabled={resizing}
          cardRef={sidebarRef}
          ref={addIntroTarget}
        />
        {isMobile && (
          <Button
            className="nav-prev"
            disabled={
              activityState.practiceMode ||
              activityState.taskId !== "training" ||
              (activityState.moduleId === "module_1" &&
                ((activityState.scenarioId === "intersections_1" &&
                  activityState.activityId === "S") ||
                  (activityState.scenarioId === "intersections_2" &&
                    activityState.activityId === "AM")))
            }
            variant="dark"
            fontSize="1.5rem"
            bgColor="var(--nav-btn-bg-color1)"
            icon={<IconChevronLeft stroke={2.7} />}
            label={wideMobile && "Prev"}
            onClick={handleNavigate}
            styles={{ width: "100%" }}
            tippy={{
              content: !wideMobile && "Previous Activity",
              placement: "right",
            }}
          />
        )}
      </Card>
    </>
  );
});

/** @type {ForwardRef<RightSidebarProps, RefType<Card>>} */
export const RightSidebar = forwardRef((props, ref) => {
  const {
    // showTimer,
    wideMobile,
    isNextButtonDisabled,
    addIntroTarget,
    // onTimerEnd,
    handleNavigate,
    introContinue,
    introContinueTippy,
    // isIntro,
  } = props;

  const { states, setStates, actions } = useContext(ScenarioInfo);
  const { isMobile } = useContext(DisplayInfo);

  const toggleHAViewsRef = useRef(null);

  const {
    activityState,
    frameOpacity,
    isDriverView,
    topView,
    topViewUpNext,
    mirrorView,
    mirrorDisabled,
    showQuestion,
    playing,
    isGridAttempted,
    allowReset,
    isCompleted,
    ended,
    gridSelections,
  } = states;
  const { setIsDriverView, setPlayerLoading } = setStates;
  const { eraseEllipse, handleToggleRVM, handlePlayAM, handleSubmit } = actions;

  const sidebarRef = useRef(null);

  const rvmIconState = mirrorDisabled || mirrorView ? "off" : "on";

  // prettier-ignore
  useImperativeHandle(ref, () => {
    return sidebarRef.current;
  }, []);

  useEffect(() => {
    if (frameOpacity && wideMobile) setToggleAspectRatio();
  }, [wideMobile]);

  useOnResize(setToggleAspectRatio, [wideMobile]);

  function setToggleAspectRatio() {
    if (!toggleHAViewsRef.current) return;
    const toggle = toggleHAViewsRef.current;
    if (!wideMobile) {
      toggle.style.aspectRatio = 39 / 70;
      return;
    }
    let rem = toggle.clientWidth / 16;
    rem = Math.max(Math.min(rem, 10), 6);
    // aspect ratio ranges from 41 / 70 to 45 / 70
    toggle.style.aspectRatio = (40 + 1.1 * (rem - 6)) / 70;
  }

  return (
    <Card
      className={clsx(
        "right",
        "sidebar",
        "sidebar-ctrls",
        isMobile && "mobile-sidebar-right",
        // showTimer && "timer-visible",
        wideMobile && "wide-mobile",
        showQuestion && "ctrls-hidden",
      )}
      height="auto"
      bgColor="var(--card-bg-color)"
      ref={(ref) => {
        if (!ref) return;
        sidebarRef.current = ref;
        addIntroTarget?.(ref);
      }}
    >
      {!isMobile && document.documentElement.clientHeight > 550 && (
        <header>{activityState.type === "HA" ? "View" : "Controls"}</header>
      )}
      {/* {isMobile && showTimer && (
        <Timer
          continued
          minutes={60}
          startOn={frameOpacity}
          onEnd={onTimerEnd}
          disabled={!isMobile}
          compact={!wideMobile}
          inactive={isIntro}
          testType={activityState.taskId}
          participantId={location.state && location.state.participantId}
          minimized
        />
      )} */}
      {activityState.type === "HA" && (
        <>
          <ButtonToggle
            variant="light"
            className={clsx(
              "views-toggle",
              isDriverView ? "driver-view" : "top-view",
            )}
            depth={isMobile && !wideMobile && "1rem"}
            choices={[
              <SVG
                fadeDurationMS={100}
                src={`${wideMobile ? "" : "mini-"}driver-view`}
              />,
              <SVG
                fadeDurationMS={100}
                src={`${wideMobile ? "" : "mini-"}top-view`}
              />,
            ]}
            tippys={[
              {
                content: "Driver View",
                placement: "left",
              },
              {
                content: "Top View",
                placement: "left",
              },
            ]}
            firstOn={isDriverView}
            stacked
            actions={[
              () => {
                if (isDriverView) return;
                setPlayerLoading(true);
                setIsDriverView(true);
              },
              () => {
                if (!isDriverView) return;
                eraseEllipse();
                setPlayerLoading(true);
                setIsDriverView(false);
              },
            ]}
            ref={toggleHAViewsRef}
          />
        </>
      )}
      {activityState.type === "AM" && (
        <>
          {!isMobile && (
            <InputHeader
              className={mirrorView ? "on" : "off"}
              label={mirrorView ? "Mirror On" : "Mirror Off"}
              style={{ marginTop: "auto", opacity: showQuestion && 0 }}
            />
          )}
          <Button
            className="rvm-toggle"
            icon={
              wideMobile || !isMobile ? (
                <SVG src={`rvm-icon-${rvmIconState}`} />
              ) : (
                <SVG src={`mini-rvm-icon-${rvmIconState}`} />
              )
            }
            fontSize={wideMobile ? "4.5rem" : "1.8rem"}
            variant="dark"
            bgColor="var(--nav-btn-bg-color1)"
            styles={{ width: "100%", marginTop: isMobile && "auto" }}
            onClick={handleToggleRVM}
            disabled={
              showQuestion || mirrorDisabled || topView || topViewUpNext
            }
            // disabled={mirrorDisabled || !playing}
            tippy={{
              content: mirrorView ? "Hide Mirror" : "Check Mirror",
              placement: "left",
            }}
          />
          <Button
            className={allowReset ? "reset" : "play"}
            fontSize="1.7rem"
            variant="dark"
            bgColor="var(--nav-btn-bg-color2)"
            styles={{ width: "100%" }}
            disabled={
              (!allowReset && playing) || topView || showQuestion || ended
            }
            // disabled={
            //   (!topView && playing) ||
            //   (topView && !allowReset) ||
            //   showQuestion ||
            //   (ended && !allowReset)
            // }
            label={wideMobile && (allowReset ? "Reset" : "Start")}
            icon={
              allowReset ? (
                <IconRefresh stroke={2.5} />
              ) : (
                <IconPlayerPlayFilled />
              )
            }
            onClick={handlePlayAM}
            tippy={{
              content: playing ? "Retry Activity" : "Begin Activity",
              placement: "left",
            }}
          />
          <Button
            className={!showQuestion && "submit-hidden"}
            fontSize="1.7rem"
            variant="dark"
            bgColor="var(--nav-btn-bg-color2)"
            styles={{ width: "100%" }}
            disabled={
              (isGridAttempted && isCompleted) || !gridSelections.length
            }
            label={wideMobile && "Submit"}
            icon={!wideMobile && <IconCheck stroke={3} />}
            onClick={handleSubmit}
          />
        </>
      )}
      {isMobile && (
        <Button
          className={clsx("nav-next", introContinue && "intro-continue")}
          variant="dark"
          fontSize="1.5rem"
          icon={<IconChevronRight stroke={2.7} />}
          label={wideMobile && "Next"}
          onClick={handleNavigate}
          disabled={introContinue ? false : isNextButtonDisabled}
          tabIndex={introContinue && 1}
          tippy={
            introContinue
              ? introContinueTippy
              : {
                  content: !props.wideMobile && "Next Activity",
                  placement: "left",
                }
          }
          reverse
        />
      )}
    </Card>
  );
});

/** @param {FooterProps} props */
export const ScenarioFooter = (props) => {
  const {
    isIntro,
    introContinue,
    introProgressData,
    introContinueTippy,
    addIntroTarget,
    nextBtnDisabled,
    practiceText,
    renderMobileProgress,
    showMobileFooter,
    handleNavigate,
    handleExit,
  } = props;

  const { states, setStates, actions } = useContext(ScenarioInfo);
  const { isMobile, isWideScreen } = useContext(DisplayInfo);

  // prettier-ignore
  const {
    activityState,
    sectionedProgressData,
    continuousProgressData,
  } = states;
  const { setIsFeedbackOpen } = setStates;
  const { toggleMobileFooter } = actions;

  const tests = ["preTest", "postTest", "postDrivingTest"];

  return isMobile ? (
    <div
      className={clsx("mobile-footer-wrapper", showMobileFooter && "shown")}
      onClick={(event) => {
        const elt = event.target;
        if (!elt.classList.contains("mobile-footer-wrapper")) return;
        toggleMobileFooter();
      }}
    >
      <Card
        className={clsx("mobile-progress", showMobileFooter && "shown")}
        bgColor="var(--card-bg-color)"
      >
        <Button
          className="scenario-exit"
          variant="dark"
          fontSize="1.5rem"
          bgColor="var(--exit-bg-color)"
          icon={<IconLogout2 stroke={2.2} />}
          onClick={handleExit}
          label={isWideScreen && "Exit"}
          tippy={{
            content: "Exit to Dashboard",
          }}
        />
        {renderMobileProgress && (
          <SectionedProgressBar
            className="progress-bar"
            hideSections={tests.includes(activityState.taskId)}
            data={
              isIntro
                ? introProgressData
                : tests.includes(activityState.taskId)
                  ? continuousProgressData
                  : sectionedProgressData
            }
          />
        )}
        <Button
          className="feedback"
          variant="dark"
          fontSize="1.5rem"
          bgColor="var(--exit-bg-color)"
          icon={<IconHelp stroke={2.3} />}
          onClick={() => toggleMobileFooter(() => setIsFeedbackOpen(true))}
          label={isWideScreen && "Help"}
          tippy={{
            content: "Help & Feedback",
          }}
        />
      </Card>
    </div>
  ) : (
    <Card className="footer" ref={addIntroTarget}>
      <Button
        className="scenario-exit"
        variant="dark"
        fontSize="1.5rem"
        bgColor="var(--exit-bg-color)"
        icon={<IconLogout2 stroke={2.2} />}
        onClick={handleExit}
        tippy={{
          content: "Exit to Dashboard",
        }}
        label={isWideScreen && "Exit"}
      />
      {!isIntro && activityState.practiceMode ? (
        <div className="practice-mode-footer">{practiceText}</div>
      ) : (
        <SectionedProgressBar
          className="progress-bar"
          hideSections={isIntro || tests.includes(activityState.taskId)}
          detachTooltips={isMobile}
          data={
            isIntro
              ? introProgressData
              : tests.includes(activityState.taskId)
                ? continuousProgressData
                : sectionedProgressData
          }
        />
      )}
      <Button
        className="feedback"
        variant="dark"
        fontSize="1.5rem"
        bgColor="var(--exit-bg-color)"
        icon={<IconHelp stroke={2.3} />}
        onClick={() => setIsFeedbackOpen(true)}
        tippy={{
          content: "Help & Feedback",
        }}
        label={isWideScreen && "Help"}
      />
      <div className="separator" />
      <div className="navigation">
        <Button
          className="nav-prev"
          variant="dark"
          disabled={
            activityState.practiceMode ||
            activityState.taskId !== "training" ||
            (activityState.moduleId === "module_1" &&
              ((activityState.scenarioId === "intersections_1" &&
                activityState.activityId === "S") ||
                (activityState.scenarioId === "intersections_2" &&
                  activityState.activityId === "AM")))
          }
          fontSize="1.5rem"
          bgColor="var(--nav-btn-bg-color1)"
          icon={<IconChevronLeft stroke={2.7} />}
          onClick={handleNavigate}
        />
        <Button
          className={clsx("nav-next", introContinue && "intro-continue")}
          variant="dark"
          fontSize="1.5rem"
          icon={<IconChevronRight stroke={2.7} />}
          label="Next"
          onClick={handleNavigate}
          disabled={introContinue ? false : nextBtnDisabled}
          tabIndex={introContinue && 1}
          tippy={introContinue && introContinueTippy}
          reverse
        />
      </div>
    </Card>
  );
};
