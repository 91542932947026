import axios from "axios";
import forge from "node-forge";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  ServerUrl,
  copy,
  defaults,
  emailFilters,
  encryptWithPublicKey,
  getSubset,
  keyEnter,
  pause,
} from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import { Button, setLoading, shake, stopLoading } from "./Button";
import ForgotPasswordRequest from "./ForgotPasswordRequest";
import InputField from "./InputField";
import Modal, { ScrollToError, focusFirstField, swapContent } from "./Modal";

/**
 * @param {{
 *   state: [boolean, function];
 *   recaptchaRef: recaptchaRef;
 * }} props
 */
const LoginModal = (props) => {
  const defaultState = copy(defaults.login);
  const recaptchaRef = props.recaptchaRef;
  const [isOpen, setIsOpen] = props.state;

  // form input and erros for username, password
  const [loginForm, setLoginForm] = useState(defaultState);
  const [loginErr, setLoginErr] = useState(defaultState);
  const resetPwState = useState(false);
  const [resetPwOpen, setResetPwOpen] = resetPwState;
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      recaptchaRef.current.execute();
    }
  }, [isOpen]);

  const setEmail = (email) => setLoginForm({ ...loginForm, email: email });
  const emailState = [loginForm.email, setEmail];

  /** @type {InputChangeHandler} */
  function formInput(event) {
    const input = event.target;
    if (input) {
      setLoginForm({
        ...loginForm,
        [input.id]: input.value.trim(),
      });
    }
  }

  async function handleLogin(event) {
    recaptchaRef.current.execute();
    let validForm = true;
    let errors = defaultState;
    const btn = event.target;
    let user = {};
    for (let field in loginForm) {
      if (!loginForm[field]) {
        errors[field] = "Required field missing";
        validForm = false;
      }
    }
    setLoginErr(errors);
    if (!validForm) {
      stopLoading(btn);
      shake(btn);
      return;
    }
    setLoading(btn);
    const encryptedPassword = encryptWithPublicKey(loginForm.password);
    await axios
      .post(
        `${ServerUrl}/users/login`,
        {
          email: loginForm.email,
          password: encryptedPassword,
          recaptchaToken: recaptchaRef.current.getValue(),
        },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        user = res.data.data;
      })
      .catch((err) => {
        console.log(err);
        // check network error
        if (err.code === "ERR_NETWORK") {
          setLoginErr({
            ...defaultState,
            email: "Login failed due to network error",
          });
          validForm = false;
          return;
        }
        if (
          err.response.data.status === 403 &&
          err.response.data.data === "role"
        ) {
          navigate("/study-concluded");
        }
        const res = err.response;
        setLoginErr({
          ...defaultState,
          [res.data.data]: res.data.message,
        });
        validForm = false;
      });
    if (!validForm) {
      stopLoading(btn);
      shake(btn);
      return;
    }
    closeModal();

    const md = forge.md.sha256.create();
    md.update(user.email);
    if (user.role === "RESEARCHER") {
      navigate("/research/search", {
        state: {
          ...getSubset(user, defaults.dashboardState),
          emailHash: md.digest().toHex(),
        },
      });
    } else {
      navigate("/dashboard", {
        state: {
          ...getSubset(user, defaults.dashboardState),
          emailHash: md.digest().toHex(),
        },
      });
    }
  }

  async function openForgotPassword(modalContent) {
    // fade out/in and smoothly fit modalContent
    await swapContent(modalContent, async () => {
      await pause(100);
      setResetPwOpen(true);
      setLoginForm({ ...loginForm, password: "" });
      setLoginErr(defaultState);
      await pause(100);
    });
    await pause(200);
    focusFirstField(modalContent);
  }

  async function handleCancel(event) {
    event.preventDefault();
    setLoginForm(defaultState);
    closeModal();
  }

  async function closeModal() {
    await pause(100);
    setIsOpen(false);
    await pause(200);
    setResetPwOpen(false);
  }

  return (
    <Modal
      id="login-modal"
      className="login-modal"
      title={resetPwOpen ? "Password Reset" : "Account Login"}
      contentClassName="login-form"
      contentLabel="Login Modal"
      isOpen={isOpen}
      close={handleCancel}
      afterClose={() => setLoginErr(defaultState)}
      focusOnOpen
    >
      <Helmet>
        <title>Login | Hazard Perception</title>
      </Helmet>
      <ScrollToError deps={[loginErr]} />
      {!resetPwOpen && (
        <LoginForm
          onFormInput={formInput}
          onLoginClick={handleLogin}
          openForgotPW={openForgotPassword}
          loginForm={loginForm}
          loginErr={loginErr}
        />
      )}
      <ForgotPasswordRequest openState={resetPwState} emailState={emailState} />
    </Modal>
  );
};

/**
 * @param {{
 *   onFormInput: function;
 *   onLoginClick: function;
 *   openForgotPW: function;
 *   loginForm: defaults["login"];
 *   loginErr: defaults["login"];
 * }} props
 */
const LoginForm = (props) => {
  const { onFormInput, onLoginClick, openForgotPW, ...formState } = props;
  const { loginForm, loginErr } = formState;

  const { contentElement } = useContext(ModalContext);

  return (
    <>
      <div className="input-col">
        <InputField
          id="email"
          label="Email"
          autoComplete="email"
          error={loginErr.email}
          value={loginForm.email}
          filters={emailFilters}
          onChange={onFormInput}
        />
        <InputField
          type="password"
          id="password"
          label="Password"
          autoComplete="current-password"
          error={loginErr.password}
          value={loginForm.password}
          onChange={onFormInput}
        />
        {/* TODO: Password checklist here */}
        <a
          className="forgot-pw"
          onClick={() => openForgotPW(contentElement)}
          onKeyDown={(event) => {
            keyEnter(event, () => openForgotPW(contentElement));
          }}
          tabIndex={0}
        >
          Forgot Password?
        </a>
      </div>
      <Button
        type="submit"
        label="Sign in"
        onClick={onLoginClick}
        styles={{
          marginBottom: "1rem",
        }}
      />
    </>
  );
};

export default LoginModal;
