import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import "../styles/unauthorized.css";

function Unauthorized() {
  const navigate = useNavigate();
  document.body.id = "unauthorized";

  return (
    <>
      <Helmet>
        <title>Unauthorized | Hazard Perception</title>
      </Helmet>
      <header>Session Timed Out</header>
      <p className="time-out-msg">
        Your session timed out while you were away. To access this content,
        please sign back in from the Homepage.
      </p>
      <Button
        label="Return Home"
        variant="light"
        depth="1.2rem"
        onClick={() => {
          navigate("/home");
        }}
        fontSize="2rem"
      />
    </>
  );
}

export default Unauthorized;
