import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { useContext, useState } from "react";
import ProfileModal from "../components/ProfileModal";
import ProfilePhoto from "../components/ProfilePhoto";
import { DisplayInfo } from "../contexts/Contexts";

/**
 * @param {{
 *   userInfo: object;
 *   afterTitle: React.JSX.Element;
 *   children: React.ReactNode;
 * }} props
 */
const DashContainer = (props) => {
  const { userInfo, afterTitle, children } = props;

  const { isTablet } = useContext(DisplayInfo);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  OverlayScrollbars.plugin(ClickScrollPlugin);
  const name = userInfo?.firstName || "";

  return (
    <OverlayScrollbarsComponent
      className="scroll-container dash"
      options={{
        scrollbars: {
          clickScroll: true,
        },
      }}
    >
      <div className="dashboard-title">
        <ProfilePhoto
          source="https://www.w3schools.com/howto/img_avatar.png"
          onClick={() => setIsProfileOpen(true)}
        />
        <header style={{ opacity: name ? 1 : 0 }}>
          {isTablet ? "Welcome!" : `Welcome, ${name}!`}
        </header>
        {afterTitle}
      </div>
      {children}
      <ProfileModal
        openState={[isProfileOpen, setIsProfileOpen]}
        userInfo={userInfo}
      />
    </OverlayScrollbarsComponent>
  );
};

export default DashContainer;
